/**
 * Strip HTML tags from a string
 * @param {String} html
 * @return {String} Returns the stripped string.
 */
const stripTags = (html) => {
  const document = new DOMParser().parseFromString(html, 'text/html');
  return document.body.textContent;
};

export default stripTags;
