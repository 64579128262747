import React from 'react';
import { DataroomTenant } from '../domain/vo/types/DataroomTenant';
import TenantContextProvider, { TenantContext, useTenantContext } from '@/Framework/Tenant/TenantContext';

export interface IDataroomTenantContext {
  tenant: DataroomTenant,
}

export const DataroomTenantContext = TenantContext;

export const useDataroomTenantContext = () => useTenantContext() as IDataroomTenantContext;

interface IProps {
  children: React.ReactNode,
  tenant: DataroomTenant,
}

function DataroomTenantContextProvider({ tenant, children }: IProps) {
  return (
    <TenantContextProvider tenant={ tenant }>
      { children }
    </TenantContextProvider>
  );
}

export default DataroomTenantContextProvider;
