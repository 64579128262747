import JsonRpcDispatcher, { interceptors } from '@dealroadshow/json-rpc-dispatcher';
import { Method } from 'constitute';
import dataroomUrl from '@/dataroom/infrastructure/dataroomUrl';
import dataroomTwoFactorAuthentication
  from '@/dataroom/application/DI/Rpc/interceptors/dataroomTwoFactorAuthentication';
import AdapterFactory from '@/Framework/DI/Providers/Rpc/AdapterFactory';
import addSessionData from '@/users/application/Session/interceptors/rpc/addSessionData';
import checkSession from '@/users/application/Session/interceptors/rpc/checkSession';
import parseWrappedError from '@/Framework/api/Rpc/parseWrappedError';
import { DataroomTenant } from '@/dataroom/domain/vo/types/DataroomTenant';
import Container from '@/Framework/DI/Container';
import { IDataroomTenantContext, DataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';

const dispatchers = new Map<DataroomTenant, JsonRpcDispatcher>();

class FactoryBuilder {
  container: Container;

  constructor(container) {
    this.container = container;
  }

  getFactory() {
    return (tenantCode: DataroomTenant = null): JsonRpcDispatcher => {
      const tenant = tenantCode || this.container.get<IDataroomTenantContext>(DataroomTenantContext).tenant;

      let dispatcher = dispatchers.get(tenant);
      if (dispatcher) {
        return dispatcher;
      }

      const adapterFactory = new AdapterFactory(dataroomUrl(tenant).getRpcUrl(), this.container);

      dispatcher = new JsonRpcDispatcher(adapterFactory.getAdapter());
      dispatcher
        .interceptRequest(addSessionData(this.container))
        .interceptResponse(checkSession(this.container))
        .interceptResponse(dataroomTwoFactorAuthentication(this.container))
        .interceptResponse(parseWrappedError())
        .interceptResponse(interceptors.response.transformErrorToException);

      dispatchers.set(tenant, dispatcher);
      return dispatcher;
    };
  }
}

export default new Method(
  (container: Container) => {
    const factoryBuilder = new FactoryBuilder(container);
    return factoryBuilder.getFactory();
  },
  [Container],
) as ReturnType<InstanceType<typeof FactoryBuilder>['getFactory']>;
