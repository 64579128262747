import cn from 'classnames';
import React from 'react';
import finsightWebUrl from '@/finsight/infrastructure/finsightWebUrl';
import termsOfUseStyles from './termsOfUse.scss';

interface IProps {
  className?: string,
}

const TermsOfUse = (props: IProps) => (
  <div className={ cn(termsOfUseStyles.wrp, props.className) }>
    By continuing, you agree to FINSIGHT's{ ' ' }<br />
    <a
      href={ finsightWebUrl.getTermsOfUseUrl() }
      target="_blank"
    >
      Terms of Use
    </a>{ ' ' }
    and{ ' ' }
    <a
      href={ finsightWebUrl.getPrivacyPolicyUrl() }
      target="_blank"
    >
      Privacy Policy
    </a>
  </div>
);

export default TermsOfUse;
